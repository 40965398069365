
import ProfessionTiles from '@/components/identity/ProfessionTiles/ProfessionTiles.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'SelectBrandProfession',
  components: {
    PDEHeading,
    ProfessionTiles,
  },

  methods: {
    navigate({ group, url, value }) {
      value === 'employers' ? this.$router.push(url) : this.$router.push(`/brand-specialty-select/${group}`)
    },
  },
}
